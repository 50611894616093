import { Controller } from "@hotwired/stimulus"
import tippy from 'tippy.js';

export default class TooltipController extends Controller {
  connect() {
    this._init();
  }

  _init() {
    let placement = 'top';

    if ($(this.element).attr('data-tooltip-placement')) {
      placement = $(this.element).attr('data-tooltip-placement');
    }

    if ($(this.element).attr('data-tooltip-disabled') !== 'true' && $(this.element).attr('data-tooltip')) {
      this.tooltipInstance = tippy(this.element, {
        placement: placement,
        animation: 'scale-subtle',
        content: this.element.getAttribute('data-tooltip')
      });

      console.info(`[DEV] Tippy initialized for: `, this.element);
    }
  }

  updateText(event) {
    const newText = event.detail.text;
    console.log("newText");
    this.element.setAttribute('data-tooltip', newText);

    if (this.tooltipInstance) {
      this.tooltipInstance.setContent(newText);
    } else {
      this._init();
    }
  }
}
